.custom-badge {
  position: absolute;
  top: -2px;
  right: -5px;
  transform: scale(0.7);
}
.custom-item {
  margin-left: 10px;
  margin-right: 10px;
  display: flex;
  align-items: center;
}
.custom-item-ios {
  margin-left: 10px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  margin-top: -3px;
  margin-bottom: -3px;
}

.custom-table td {
  padding: 5px;
}
