.messages {
  margin-bottom: 6px;
  display: flex;
  flex-direction: column;
}

.message {
  border-radius: 20px;
  padding: 8px 15px;
  margin-top: 0px;
  margin-bottom: 6px;
  display: inline-block;
}

.yours {
  align-items: flex-start;
}

.yours .message {
  margin-right: 25%;
  margin-left: 15px;
  background-color: #eee;
  color: #111;
  position: relative;
}

.yours .message.last:before {
  content: "";
  position: absolute;
  z-index: 0;
  bottom: 0;
  left: -7px;
  height: 20px;
  width: 20px;
  background: #eee;
  border-bottom-right-radius: 15px;
}
.yours .message.last:after {
  content: "";
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: -10px;
  width: 10px;
  height: 20px;
  background: var(--ion-background-color, #fff);
  border-bottom-right-radius: 10px;
}

.dark .yours .message {
  background-color: #333 !important;
  color: white;
}
.dark .yours .message:before {
  background-color: #333 !important;
  color: white;
}
.yours .time,
.system .time {
  font-size: 12px;
  color: #888;
}

.mine {
  align-items: flex-end;
}

.mine .message {
  color: white;
  margin-left: 25%;
  margin-right: 15px;
  background: linear-gradient(to bottom, #00d0ea 0%, #0085d1 100%);
  background-attachment: fixed;
  position: relative;
}

.mine .message.last:before {
  content: "";
  position: absolute;
  z-index: 0;
  bottom: 0;
  right: -8px;
  height: 20px;
  width: 20px;
  background: linear-gradient(to bottom, #00d0ea 0%, #0085d1 100%);
  background-attachment: fixed;
  border-bottom-left-radius: 15px;
}

.mine .message.last:after {
  content: "";
  position: absolute;
  z-index: 1;
  bottom: 0;
  right: -10px;
  width: 10px;
  height: 20px;
  background: var(--ion-background-color, #fff);
  border-bottom-left-radius: 10px;
}

.mine .time {
  font-size: 12px;
  color: #eee;
}

.system .message {
  margin: 0 auto 10px;
  background-color: rgb(226, 241, 255);
  color: #222;
  position: relative;
}
.dark .system .message {
  background-color: #222;
  color: #eee;
}

.unreadmessage {
  text-align: center;
  padding: 5px;
  color: #333;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  background-color: #eee;
  margin-bottom: 10px;
}
.dark .unreadmessage {
  border-color: #000;
  background-color: #222;
  color: silver;
}
